import React from 'react'
import Linkbox from '../linkbox'
import LinkboxGallery from '../linkboxGallery/index'
import desktopimage from '../../assets/three-avatars.png'
import desktopimage2 from '../../assets/ai-landscape.png'
import desktopimage3 from '../../assets/aboutus-banner.png'
import mobileImage from '../../assets/app-access-mobile.png'
import mobileImage2 from '../../assets/ai-land-mobile.png'
import mobileImage3 from '../../assets/office-mobile.png'
import mobileImage4 from '../../assets/model-mobile.png'
import mobileImage5 from '../../assets/studio-mobile.png'
import mobileImage6 from '../../assets/aboutus-banner-mobile.png'

import { PATHS } from '../../utils/vars'

import styles from './InfoPanel.module.scss'

const InfoPanel = () => (
    <>
        <div className={styles.infoPanelDesktop}>
            <Linkbox
                title="COOLAB.AI"
                subtitle="design platform"
                image={desktopimage}
                url={PATHS.APP}
                alt="grid of templates"
            />
            <Linkbox
                title="GENERATIVE FUTURES"
                subtitle="foresight project"
                image={desktopimage2}
                url={PATHS.FORESIGHT}
                alt="three ai models"
            />
            <Linkbox
                title="ABOUT URBANCOOLAB"
                subtitle=" "
                image={desktopimage3}
                url={PATHS.ABOUT_US}
                alt="the lab"
            />
            <LinkboxGallery />
        </div>
        <div className={styles.infoPanelMobile}>
            <Linkbox
                title="COOLAB.AI"
                subtitle="DESIGN PLATFORM"
                image={mobileImage}
                url={PATHS.APP}
                alt="three hero avatar characters"
            />
            <Linkbox
                title="GENERATIVE FUTURES"
                subtitle="FORESIGHT PROJECT"
                image={mobileImage2}
                alt="ai generated model"
                url={PATHS.FORESIGHT}
            />
            <Linkbox
                title="ABOUT URBANCOOLAB"
                subtitle=" "
                image={mobileImage6}
                alt="the lab"
                url={PATHS.ABOUT_US}
            />
            <Linkbox
                title="CANVA VS COOLAB"
                subtitle="design off"
                image={mobileImage5}
                alt="rotating cylinders with a neon yellow hue"
                url={PATHS.COMPARE}
            />
            <Linkbox
                title="COOLAB.AI"
                subtitle="design blog"
                image={mobileImage3}
                alt="A.I generated picture of an office"
                url={PATHS.BLOG}
            />
            <Linkbox
                title="ATELIER UCL"
                subtitle="custom projects"
                image={mobileImage4}
                alt="Cool person wearing a mask in front of an old building"
                url={PATHS.ATELIER}
            />
        </div>
    </>
)

export default InfoPanel
