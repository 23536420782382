import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'

import styles from './FooterSection.module.scss'
import arrow from '../../assets/arrow.svg'

const FooterSection = ({ heading, links }) => {
    const [expanded, expand] = useState(false)

    useEffect(() => {
        expand(false)
    }, [])

    return (
        <div className={`${styles.footerSection}`}>
            <div className={styles.largeFooter}>
                <h3 className={styles.heading}>{heading}</h3>
                <ul className={styles.links}>
                    {
                        links.map(({
                            name, subtext, href,
                        }) => (
                            <li key={name} className={styles.linkItem}>
                                <Link href={href}>
                                    {
                                        subtext ? (
                                            <a className={`${styles.combined} ${styles.link}`} target="_blank" rel="noopener noreferrer">
                                                <span className={styles.main}>{name}</span>
                                                <span className={styles.sub}>{subtext}</span>
                                            </a>
                                        ) : (
                                            <a className={styles.link}>{name}</a>
                                        )
                                    }
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className={styles.accordion}>
                <button type="button" onClick={() => expand(!expanded)}>
                    <h3>{heading}</h3>
                    <img alt="" style={{ transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' }} className={styles.arrow} src={arrow} />
                </button>
                <div className={styles.collapse} style={{ maxHeight: expanded ? '600px' : '0px' }}>
                    <ul className={styles.links}>
                        {
                            links.map(({
                                name, href, subtext,
                            }) => (
                                <li key={name} className={styles.linkItem}>
                                    <Link href={href}>
                                        {
                                            subtext ? (
                                                <a className={`${styles.combined} ${styles.link}`} target="_blank" rel="noopener noreferrer">
                                                    <span className={styles.main}>{name}</span>
                                                    <span className={styles.sub}>{subtext}</span>
                                                </a>
                                            ) : (
                                                <a className={styles.link}>{name}</a>
                                            )
                                        }
                                    </Link>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

FooterSection.propTypes = {
    heading: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        href: PropTypes.string,
        subtext: PropTypes.string,
    })).isRequired,
}

export default FooterSection
