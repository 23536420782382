import React from 'react'
import PropTypes from 'prop-types'
import CharterMemberItem from '../chartermemberitem'
import styles from './CharterMemberFeed.module.scss'

const CharterMemberFeed = ({
    feed: {
        title,
        memberItems,
    },
}) => (
    <div className={styles.memberFeed}>
        <h3 className={styles.header}>{title}</h3>
        <div className={`${styles.flexDisplay} row`}>
            {
                memberItems.map(({
                    fields: {
                        memberTitle, link, image, image: {
                            fields: {
                                description,
                            },
                        },
                    },
                    sys: {
                        id,
                    },
                }, idx) => (
                    <CharterMemberItem
                        key={id}
                        idx={idx}
                        image={image?.fields?.file?.url}
                        title={memberTitle}
                        alt={description}
                        link={link}
                    />
                ))
            }
        </div>
    </div>
)

CharterMemberFeed.propTypes = {
    feed: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default CharterMemberFeed
