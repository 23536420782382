/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import PropTypes from 'prop-types'

import styles from './Linkbox.module.scss'

const Linkbox = ({
    title, subtitle, image, url, alt,
}) => (
    <div className={styles.linkBox}>
        <a href={url} className={styles.title}>
            <img src={image} alt={alt} />
            <div className={styles.text}>
                <h3 className={styles.subtitle}>{subtitle}</h3>
                <h2>{title}</h2>
            </div>
        </a>
    </div>
)

Linkbox.propTypes = {
    title: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
}

export default Linkbox
