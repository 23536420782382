/* eslint-disable max-len */
import React from 'react'

import ReactPlayer from 'react-player'

import styles from './BannerVideo.module.scss'

const BannerVideo = () => (
    <div className={styles.bannerWrapper}>
        <div className={styles.srOnly}>
            <h1>Eat. Sleep. Dance. Collect NFT.</h1>
            <h2>Welcome to Urbancoolab</h2>
        </div>
        <header className={styles.bannerVideo}>
            <ReactPlayer
                url="https://vimeo.com/692338254"
                allowFullScreen
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                className="react-player"
                width="100%"
                height="100%"
                loop
                controls
            />
        </header>
        <div className={styles.stockTicker}>
            <h6>artificial imagination powered by artificial intelligence</h6>
        </div>
    </div>

)

export default BannerVideo
