import React from 'react'
import PropTypes from 'prop-types'

import styles from './SquareImage.module.scss'

const SquareImage = ({
    alt, src, overlay, textOverlay,
}) => (
    <div className={styles.squareImage}>
        {
            overlay && (
                <a
                    href={overlay.link}
                    className={styles.overlay}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <p className={styles.excerpt}>{overlay.excerpt}</p>
                    <p className={styles.readMore}>
                        Read more
                    </p>
                </a>
            )
        }
        {
            textOverlay && (
                <div className={styles.memberOverlay}>
                    <p className={`${styles.overlayText}`}>{textOverlay}</p>
                </div>
            )
        }
        <img
            alt={alt}
            src={src}
        />
    </div>
)

SquareImage.defaultProps = {
    overlay: null,
    textOverlay: '',
}

SquareImage.propTypes = {
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    textOverlay: PropTypes.string,
    overlay: PropTypes.exact({
        link: PropTypes.string,
        excerpt: PropTypes.string,
    }),
}

export default SquareImage
