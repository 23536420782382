import React from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import ContentfulClient from '../contentful'
import Layout from '../components/layout'
import { LANDING_PAGE_CONTENTFUL_ID } from '../utils/vars'
import BannerVideo from '../components/bannervideo'
import CharterMemberFeed from '../components/chartermemberfeed'
import InfoPanel from '../components/infopanel'
import MetaHeaders from '../components/metaheaders'

const Home = ({
    metaTitle,
    metaDescription,
    charterMemberFeed,
    stichLogo,
}) => (
    <div className="home">
        <MetaHeaders title={metaTitle} description={metaDescription} />
        <Layout>
            <BannerVideo />
            <div className="containerBody">
                <InfoPanel
                    logo={stichLogo}
                />
                <LazyLoad height={200} offset={500} once>
                    <CharterMemberFeed feed={charterMemberFeed} />
                </LazyLoad>
            </div>
        </Layout>
    </div>
)
Home.getInitialProps = async () => {
    const res = await ContentfulClient.getEntries({ include: 3, 'sys.id': LANDING_PAGE_CONTENTFUL_ID })
    return {
        ...res.items[0].fields,
        stichLogo: res.items[0].fields.stichLogo.fields,
        ucxLogo: res.items[0].fields.ucxLogo.fields,
        stories: res.items[0].fields.stories.fields,
        userKnowledge: res.items[0].fields.userKnowledge.map((item) => item.fields),
        blogFeed: res.items[0].fields.blogSection.fields,
        charterMemberFeed: res.items[0].fields.charterMemberSection.fields,
        getStarted: res.items[0].fields.getStarted.fields,
        bannerGlitchImages: res.items[0].fields.bannerGlitchImages,
    }
}

Home.propTypes = {
    metaTitle: PropTypes.string.isRequired,
    metaDescription: PropTypes.string.isRequired,
    charterMemberFeed: PropTypes.objectOf(
        PropTypes.any,
    ).isRequired,
    stichLogo: PropTypes.objectOf(
        PropTypes.any,
    ).isRequired,
}

export default Home
