import React from 'react'

import image3 from '../../assets/office-crew.png'
import image4 from '../../assets/two-models.png'
import image5 from '../../assets/person-face.png'

import { PATHS } from '../../utils/vars'

import styles from './LinkboxGallery.module.scss'

function LinkboxGallery() {
    return (
        <div className={styles.linkBoxGallery}>
            <div className={styles.gallery}>
                <div className={styles.linkBoxGalleryLeft}>
                    <div className={styles.linkBox}>
                        <a href={PATHS.BLOG}>
                            <img src={image3} alt="hypecoolture magazine link" />
                            <div className={styles.text}>
                                <h3 className={styles.subtitle}>design blog</h3>
                                <h2>COOLAB.AI</h2>
                            </div>
                        </a>
                    </div>
                    <div className={styles.linkBox}>
                        <a href={PATHS.ATELIER}>
                            <img src={image4} alt="atelier link" />
                            <div className={styles.text}>
                                <h3 className={styles.subtitle}>custom projects</h3>
                                <h2>Atelier UCL</h2>
                            </div>
                        </a>
                    </div>
                </div>
                <div className={styles.linkBoxGalleryRight}>
                    <div className={styles.linkBox}>
                        <a href={PATHS.COMPARE}>
                            <img src={image5} alt="stich logo" />
                            <div className={styles.text}>
                                <h3 className={styles.subtitle}>design off</h3>
                                <h2>CANVA VS COOLAB</h2>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LinkboxGallery
