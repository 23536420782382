/* eslint-disable react/destructuring-assignment, react/prop-types */
import React, { useEffect } from 'react'
import Header from '../header'
import Footer from '../footer'
import CookiePopup from '../cookiepopup'
import { initGTM } from '../../utils/tagmanager'

const LayoutWeb3 = (props) => {
    useEffect(() => {
        if (!window.TAGS_INITIALIZED) {
            initGTM()
            window.TAGS_INITIALIZED = true
        }
    }, [])

    const styles = {
        textAlign: 'center',
        position: 'absolute',
        bottom: '15px',
        right: 0,
        left: 0,
        fontSize: '16px',
    }

    return (
        <>
            <Header />
            { props.children }
            <CookiePopup />
            {!props.showFooter && props.showFooter !== undefined
                ? <div style={styles}>© urbancoolab 2021</div>
                : (<Footer />)}
        </>
    )
}

export default LayoutWeb3
