import React from 'react'
import Link from 'next/link'
import FooterSection from '../footersection'
import fb from '../../assets/fb.svg'
import insta from '../../assets/insta.svg'

import styles from './Footer.module.scss'
import { PATHS } from '../../utils/vars'

const Footer = () => (
    <div className={styles.footer}>
        <div className="container">
            <div className={`${styles.top} row`}>
                <FooterSection
                    heading="About us"
                    links={[
                        { href: PATHS.APP_TEAM, name: 'Team' },
                        { href: PATHS.CAREERS, name: 'Jobs' },
                    ]}
                />
                <FooterSection
                    heading="Get in touch"
                    links={[
                        { href: PATHS.CONTACT_US, name: 'Contact us' },
                        // { href: PATHS.CUSTOMER_CARE, name: 'Customer care' },
                    ]}
                />
                <div className={styles.footerCol} />
                <div className={`${styles.socialContainer}`}>
                    <div className={styles.socialMedia}>
                        <a target="_blank" rel="noopener noreferrer" href="//facebook.com/urbancoolab.official">
                            <img alt="Click here to go to our Facebook page" src={fb} />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="//instagram.com/urbancoolab_/">
                            <img alt="Click here to go to our Instagram page" src={insta} />
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.bottomMobile}>
                <div className={`${styles.bottomLinks} row`}>
                    <div className={styles.textRight}>
                        <Link href={PATHS.PRIVACY_POLICY}>
                            <a>Privacy Policy</a>
                        </Link>
                    </div>
                    <div className={styles.textLeft}>
                        <Link href={PATHS.TERMS_CONDITIONS}>
                            <a>Terms &amp; Conditions</a>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="colFullWidth">
                        <div className={`${styles.bottomLinks} centerMobile`}>
                            <span>© urbancoolab 2023</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.bottom}>
                <div className="row">
                    <div className="colFullWidth">
                        <div className={styles.bottomLinks}>
                            <span>© urbancoolab 2023</span>
                            <span>
                                <Link href={PATHS.PRIVACY_POLICY}>
                                    <a>Privacy Policy</a>
                                </Link>
                            </span>
                            <span>
                                <Link href={PATHS.TERMS_CONDITIONS}>
                                    <a>Terms &amp; Conditions</a>
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Footer
