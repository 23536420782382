import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { PATHS } from '../../utils/vars'
import logo from '../../assets/logo.svg'
import closedNavImg from '../../assets/ham.svg'
import openNavImg from '../../assets/x.svg'
import styles from './Header.module.scss'

const Header = () => {
    const [toggle, setToggle] = useState(false)

    const toggleImage = {
        backgroundImage: `url(${toggle ? openNavImg : closedNavImg})`,
    }

    useEffect(() => {
        setToggle(false)
    }, [])

    useEffect(() => {
        function onScroll() {
            const currentScrollPos = window.pageYOffset
            if (currentScrollPos > 1000) {
                document.getElementById('navbar').style.top = '-250px'
            } else {
                document.getElementById('navbar').style.top = '0'
            }
        }
        window.addEventListener('scroll', onScroll)

        return function unMount() {
            window.removeEventListener('scroll', onScroll)
        }
    })

    return (
        <nav style={{ maxHeight: toggle ? '600px' : '0px' }} className={`${styles.navbar}`} id="navbar">
            <Link href={PATHS.HOME} passHref>
                <a href="/" className={styles.navbarBrand}>
                    <img className={styles.logo} alt="urbancoolab" src={logo} />
                </a>
            </Link>
            <button onClick={() => setToggle(!toggle)} type="button" className={`${styles.navbarToggle} ${styles.collapsed}`} aria-label="Toggle navigation">
                <span style={toggleImage} className={styles.navbarToggleIcon} />
            </button>
            <div className={styles.navbarLinks}>
                <div className={styles.pages} />
                <div className={styles.externalSites}>
                    <a href={PATHS.ABOUT_US}>
                        <div className={styles.navMargin}>
                            <span className={styles.submain}>
                                about
                            </span>
                            <span className={styles.main}>
                                ucl
                            </span>
                        </div>
                    </a>
                    <a href={PATHS.APP}>
                        <div className={styles.navMargin}>
                            <span className={styles.submain}>
                                about
                            </span>
                            <span className={styles.main}>
                                coolab.ai
                            </span>
                        </div>
                    </a>
                </div>
            </div>
        </nav>
    )
}

export default Header
