import React from 'react'
import PropTypes from 'prop-types'
import Tilt from 'react-tilt'
import SquareImage from '../squareimage'

import styles from './CharterMemberItem.module.scss'

const CharterMemberItem = ({
    image, title, alt, link,
}) => (
    <div className={`padding ${styles.memberItem}`}>
        <Tilt className="Tilt" options={{ max: 25 }}>
            {link && link !== undefined ? (
                <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <SquareImage alt={alt} src={image} textOverlay={title} />
                </a>
            ) : (<SquareImage alt={alt} src={image} textOverlay={title} />)}

        </Tilt>
    </div>
)

CharterMemberItem.defaultProps = {
    link: undefined,
}

CharterMemberItem.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    link: PropTypes.string,
}

export default CharterMemberItem
